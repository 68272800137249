import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Row, Container, Col } from "react-bootstrap"

import YAMLdata from "./clients-list.yaml"

const ClientsListMnT = () => {
  return (
    <section className="clients_area pad_btm">
      <Container className="container">
        <Row>
          <Col>
            <div className="clients_content">
              <h2>{YAMLdata.title}</h2>
              <p>{YAMLdata.content}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="client_logo_list">
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_08.png"
                alt="XOOX Brewmill"
              />
            </div>

            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_15.png"
                alt="The Pump House"
              />
            </div>

            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_21.png"
                alt="The Brewhive"
              />
            </div>

            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_23.png"
                alt="Gateway Taproom"
              />
            </div>
            <div className="client_logo_img">
              <StaticImage
                src="../images/clients/clients_logo_24.png"
                alt="Hangover"
              />
            </div>
          </div>
        </Row>
      </Container>
    </section>
  )
}
export default ClientsListMnT
