import * as React from "react"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Row, Container, Col } from "react-bootstrap"

import ClientsListMnT from "../../../sections/clients-list-mnt"

const MicrobreweriesAndTaprooms = () => (
  <Layout>
    <Seo
      title="Microbreweries &amp; Taprooms"
      description="We specialise in F&amp;B, Leisure and Luxury spaces - crafting a complete guest experience through a multidisciplinary approach."
    />
    <section className="category_area pad_btm">
      <div className="category_outer">
        <Container className="p0" fluid>
          <Row className="no-gutters">
            <Col lg={6} xl={7} className="category_image_outer">
              <div className="category_image_inner">
                <div className="category_image">
                  <div className="cat_img">
                    <StaticImage
                      src="../../../images/projects/mnt_hero.jpg"
                      alt="Microbreweries / Taprooms"
                      layout="fullWidth"
                    />
                  </div>
                  <div className="cat_title mnt_bg">
                    <h2>
                      Microbreweries <br />
                      {"//"} Taprooms
                    </h2>
                  </div>
                  <div className="cat_icon">
                    <StaticImage
                      src="../../../images/projects/microbreweries-taprooms-xl-icon.svg"
                      alt="Microbreweries / Taprooms icon"
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} xl={5} className="cat_content_bg">
              <div className="cat_content">
                <div className="cat_heading">
                  <h3>Dynamic spaces built for a unique experience</h3>
                </div>
                <div className="cat_listing">
                  <div className="cat_lst_itm purple_squr">
                    <Link
                      to="/projects/microbreweries-&amp;-taprooms/the-pump-house"
                      className="cta-project"
                    >
                      <h4>The Pump House</h4>
                      <p>Bangalore</p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm">
                    <Link
                      to="/projects/microbreweries-&amp;-taprooms#hangover-xl"
                      className="cta-project"
                    >
                      <h4>Hangover XL</h4>
                      <p>Bangalore</p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm">
                    <Link
                      to="/projects/microbreweries-&amp;-taprooms#aurum-brew-works"
                      className="cta-project"
                    >
                      <h4>Aurum Brew Works</h4>
                      <p>Bangalore</p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm purple_squr">
                    <Link
                      to="/projects/microbreweries-&amp;-taprooms/xoox-brewmill"
                      className="cta-project"
                    >
                      <h4>XOOX Brewmill</h4>
                      <p>Bangalore</p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm">
                    <Link
                      to="/projects/microbreweries-&amp;-taprooms#the-brewhive"
                      className="cta-project"
                    >
                      <h4>The Brewhive</h4>
                      <p>Kolkata</p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm">
                    <Link
                      to="/projects/microbreweries-&amp;-taprooms#gateway-taproom"
                      className="cta-project"
                    >
                      <h4>Gateway Taproom</h4>
                      <p>Mumbai</p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm purple_squr">
                    <Link
                      to="/projects/microbreweries-&amp;-taprooms/the-reservoire"
                      className="cta-project"
                    >
                      <h4>The Reservoire</h4>
                      <p>Bangalore</p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm">
                    <Link
                      to="/projects/microbreweries-&amp;-taprooms#the-fixx-bar"
                      className="cta-project"
                    >
                      <h4>The Fixx Bar</h4>
                      <p>Bangalore</p>
                    </Link>
                  </div>
                  <div className="cat_lst_itm">
                    <Link
                      to="/projects/microbreweries-&amp;-taprooms#1522"
                      className="cta-project"
                    >
                      <h4>1522</h4>
                      <p>Bangalore</p>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
    <ClientsListMnT />
  </Layout>
)

export default MicrobreweriesAndTaprooms
